window.dataLayer = window.dataLayer || [];

export function identify(userId) {
  window.dataLayer.push({ user_id: userId });
}

function loadGoogleAnalytics() {
  if (!process.env.GOOGLE_ANALYTICS_KEY) return;
  if (!App.cookieConsentStore?.read().marketing_enabled) return;
  if (!App.cookieConsentStore?.read().evaluation_enabled) return;

  const script = document.createRange().createContextualFragment(`
    <script async src="https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_KEY}"></script>
  `);

  // Documentation says it should go immediately after the opening <head> element.
  document.head.prepend(script);

  const gtag = function () {
    dataLayer.push(arguments);
  };

  gtag("js", new Date());
  gtag("config", process.env.GOOGLE_ANALYTICS_KEY);
}

App.cookieConsentStore?.subscribe(() => loadGoogleAnalytics());
loadGoogleAnalytics();
